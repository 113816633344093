import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController, ToastController, AlertController} from '@ionic/angular';
import { ServiciosService } from '../servicios.service';
import { ModalController } from '@ionic/angular';
import { ClientePage } from '../cliente/cliente.page';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.page.html',
  styleUrls: ['./clientes.page.scss'],
})
export class ClientesPage {
    

    private loading:any;
    private modal:any;
    private DATOS:any;
    private DATOSB:any;
    private BUSCAR:any;

  constructor(private servicio:ServiciosService, private http: HttpClient, private alertCtrl:AlertController, private loadingController: LoadingController, private toastController: ToastController,private modalCtrl:ModalController, public modalController: ModalController) {
      
      this.DATOSB=[];
      this.BUSCAR = "";
  
  }

    
    
    
    filtrar()
    {
        this.DATOSB = this.BuscarItem(this.BUSCAR);
    }
    
      BuscarItem(t){
         
         var re = new RegExp ( t.toUpperCase() + ".*");
         let r = new Array;  
         let i = 0;
      
          console.log("buscando: ",re);
        for (let o of this.DATOS)
        {
           // console.log(o);
            try{
                if ((o.NOMBRE.toUpperCase()).match(re)){
                //if ((o.NOMBRE.toUpperCase()).match(re) || (o.DIRECCION.toUpperCase()).match(re) || (o.TELEFONO.toUpperCase()).match(re) ){
                    i++;
                    r.push(o);
                    if(i>20){return r;}
        
                }
                
            }catch(e){}
                

        }
      
          return r;

  } 
    
    
     
  
    
     seleccionar(c)
    {
        


        this.modalCtrl.dismiss(c);

        
    }
    
    editar(c)
    {
        this.presentModalE(c);
    }
    
    cerrar()
    {
        this.modalCtrl.dismiss();
    }
    
    
    
    crearCliente()
    {
        console.log("crear Cliente");
        this.presentModal();
    }
    
    
      async presentModal() {
   const modal = await this.modalController.create({
      component: ClientePage,
      componentProps: { parametros: {API:"insert/clientes",DATOS:{NOMBRE:"",DIRECCION:"",TELEFONO:""},TEXTO:"CREAR CLIENTE"} }
    });
        
        
        modal.onDidDismiss()
      .then((data:any) => {
           
       this.recargar();
    });
        
      
        
    return await modal.present();
           
  }
    
    
      async presentModalE(C) {
   const modal = await this.modalController.create({
      component: ClientePage,
      componentProps: { parametros: {API:"update/clientes",DATOS:C,TEXTO:"ACTUALIZAR CLIENTE"} }
    });
        
        
        modal.onDidDismiss()
      .then((data:any) => {
           
       this.recargar();
    });
        
      
        
    return await modal.present();
           
  }
    

    
     recargar()
  {
      
      
    console.log("Recargando");
    
     this.presentLoading("CARGANDO CONFIGURACION");
  
      
      let datos = {
    "USUARIO":this.servicio.getID(),
    "token":this.servicio.getToken()
};

  let body = new FormData();
  body.append('datos', JSON.stringify(datos) ); 


    this.http.post(this.servicio.getAPI()+"mostrar/clientes",body).subscribe((data: any) => {
     console.log(data);
     
    
this.closeLoading();
        
      if(data.success)
      {
          this.DATOS = data.data;
          this.DATOSB = this.BuscarItem(this.BUSCAR);
          //this.formulario = data.data;
          
   this.showToast("DATOS CARGADOS");
          
      
          
  
      }else
      {
        this.showToast("No se pudo recargar");
          console.log("No se pudo recargar");
         
      }

    }, error => {
     this.closeLoading();
        console.log("Oooops!");
        this.showToast("Error de conexión al servidor");
    });

  


  }
    
     ionViewDidEnter() {
    console.log("I'm alive!");
     this.recargar();

          
  }
    
        
     async presentLoading(texto) {
    this.loading = await this.loadingController.create({
      spinner: 'bubbles',
      duration: 30000,
      message: texto,
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await this.loading.present();
  }
    
    closeLoading()
    {
       this.loading.dismiss(); 
    }
    
   
    
     async showToast(texto) {
    const toast = await this.toastController.create({
      message: texto,
      showCloseButton: true,
      duration: 3000,
      closeButtonText: 'Ok'
    });
    toast.present();
  }
    
 
    
}
