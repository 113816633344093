import { Component } from '@angular/core';
import { ServiciosService } from './servicios.service';
import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
    styleUrls: ['app.scss']
})
export class AppComponent {

    public USUARIO= [];
    public API_PIC = "./assets/icon/";
    public API = "http://logix.computronicsgm.com/";
    
    
   
    
  public appPages = [
    {
      title: 'INGRESO EQUIPOS',
      url: '/home',
      icon: 'barcode'
    },
    {
      title: 'BUSCAR EQUIPO',
      url: '/list',
      icon: 'search'
    },
      {
      title: 'PRODUCTOS',
      url: '/productos',
      icon: 'cube'
    },
      {
      title: 'CREARO ENVÍO',
      url: '/orden_despacho',
      icon: 'document'
    },
      {
      title: 'BUSCAR ENVÍO',
      url: '/buscar-envio',
      icon: 'search'
    },
      {
      title: 'BUSQUEDA AVANZADA',
      url: '/reportes',
      icon: 'paper'
    },
      
      {
      title: 'SALIR',
      url: '/login',
      icon: 'exit'
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private servicio:ServiciosService,
    public events: Events
  ) {
    this.initializeApp();
        this.USUARIO =   this.servicio.getUSUARIO();
  
    this.events.subscribe('login', data => {
          console.log("User changed!");
     this.USUARIO =   this.servicio.getUSUARIO();
          
   });
    
    
    }
    
     
    

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
