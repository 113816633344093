import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
//import { IonicStorageModule } from '@ionic/storage';
import { ClientesPageModule } from './clientes/clientes.module';
import { ClientePageModule } from './cliente/cliente.module';
import { ProductoPageModule } from './producto/producto.module';
import { EquipoPageModule } from './equipo/equipo.module';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {DatePipe} from '@angular/common';
//import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    // ClientesPageModule 
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
      ClientesPageModule,
      ClientePageModule,
      ProductoPageModule,
      EquipoPageModule
      //AngularPDFMakeModule,
    //IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
      DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
