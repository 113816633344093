import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

    
    API_PIC:any;
    USUARIO:any;
    API:any;
    DATOS:any;
    
  constructor() {
    this.USUARIO ={ID:"1", token: "d7403066713e9d99bcc97a8db69bfb67", NOMBRE:"USUARIO", NOMBRE_EMPRESA:"COMPUTRONICS GM", PATH:"if_male_628288.png"};
    this.API_PIC = "./assets/icon/";
    //this.API = "http://logix.computronicsgm.com/";
    this.API ='http://logix.computronicsgm.com/public/api/';
    
    this.DATOS = [];
      
  }
    
    setUSUARIO(u)
    {
        this.USUARIO = u;
       console.log(this.USUARIO);
    }
    
    
    getUSUARIO()
    {
        return this.USUARIO;
    }
    
    getID()
    {
      //  console.log(this.USUARIO);
      return this.USUARIO.ID;
    }
    
    getToken()
    {
        return this.USUARIO.token;
    }
    
    getNombre()
    {
        return this.USUARIO.NOMBRE;
    }
    
    
    getAPI_PIC()
    {
        return this.API_PIC;
    }
    
    getAPI()
    {
        return this.API;
    }
    
    
    getDatos()
    {
        return this.DATOS;
    }
    
    
    pushDatos(dato)
    {
        this.DATOS.push(dato);
    }
    
    
    
     BuscarItem(t){
         console.log("buscando:",t);
         
         //var re = new RegExp ( t + ".*");
         //var re = new RegExp ( t );
         
         let r = new Array;  
      
        for (let o of this.DATOS)
        {
           // console.log(o[1].NUMERO,o[1].NUMERO=== t);
            try{
                if (o[1].NUMERO=== t){
                   return o;
                    break;
        
                }
                
            }catch(e){ console.log(e);}
                

        }
      
          return r;

  } 
    
    
}
