import { Component,OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController, ToastController, AlertController, ModalController} from '@ionic/angular';
import { ServiciosService } from '../servicios.service';
@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.page.html',
  styleUrls: ['./cliente.page.scss'],
})
export class ClientePage implements OnInit {
    
    private CLIENTE:any;
    private loading:any;
    private modal:any;
    @Input("parametros") parametros;

  constructor(private servicio:ServiciosService, private http: HttpClient, private alertCtrl:AlertController, private loadingController: LoadingController, private toastController: ToastController, private modalCtrl:ModalController) { 
  
      this.CLIENTE={NOMBRE:"",DIRECCION:"",TELEFONO:""};
      
      
  }

    
     ngOnInit() {
  console.log(this.parametros);
         this.CLIENTE = this.parametros.DATOS;
  }
      
     crear()
  {
      
      
    console.log("Creando");
    
     this.presentLoading("Sincronizando...");
  
      
      let credenciales = {
    "USUARIO":this.servicio.getID(),
    "token":this.servicio.getToken()
};
      
      

  let body = new FormData();
  body.append('credenciales', JSON.stringify(credenciales) ); 
  body.append('datos', JSON.stringify(this.CLIENTE) ); 


    this.http.post(this.servicio.getAPI()+this.parametros.API,body).subscribe((data: any) => {
     console.log(data);
     
    
this.closeLoading();
        
      if(data.success)
      {
         // this.DATOS = data.data;
          //this.formulario = data.data;
          
   this.showToast("EXITO AL GUARDAR");
          
      this.modalCtrl.dismiss();
          
  
      }else
      {
        this.showToast("No se pudo recargar");
          console.log("No se pudo recargar");
         
      }

    }, error => {
     this.closeLoading();
        console.log("Oooops!");
        this.showToast("Error de conexión al servidor");
    });

  


  }
    
    
    cerrar()
    {
        this.modalCtrl.dismiss();
    }
    
    
        async presentLoading(texto) {
    this.loading = await this.loadingController.create({
      spinner: 'bubbles',
      duration: 30000,
      message: texto,
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    return await this.loading.present();
  }
    
    closeLoading()
    {
       this.loading.dismiss(); 
    }
    
    
      async showToast(texto) {
    const toast = await this.toastController.create({
      message: texto,
      showCloseButton: true,
      duration: 3000,
      closeButtonText: 'Ok'
    });
    toast.present();
  }
    
 

}
